import mitt from "mitt";

const globals = {
    /** @type {import("mitt").Emitter} */
    emitter: null,
    initialized: false
};

function requireInitialization() {
    if( ! globals.initialized) {
        throw new Error(`You must call initEvents one time before calling this function`);
    }
}

/**
 * 
 * @param {import("mitt").Emitter} emitter 
 */
export function setEmitter( emitter ) {
    requireInitialization();
    globals.emitter = emitter;
}

/**
 * Initializes the event emitter inside the Vue-Component-Library. You can optionally provide your own emitter
 * to replace the emitter set up by Vue-Component-Library. Replacing the emitter used in Vue-Component-Library is recommended if you have an event emitter in your app already.
 * @param {import("mitt").Emitter} [emitter] 
 */
export function initEvents( emitter ) {
    if(globals.initialized) {
        throw new Error(`initEvents should not be called more than once in application`);
    }
    globals.initialized = true;
    if(emitter) {
        setEmitter( emitter );
    } else {
        globals.emitter = mitt();
    }
}

/**
 * Returns the event emitter used by Vue-Component-Library.
 * @returns {mitt}
 */
export function getEmitter() {
    requireInitialization();
    return globals.emitter;
}